@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
* {
  user-select: none;
  font-family: 'Roboto', sans-serif;
}

.thinfont {
  font-weight: 100 !important;
  font-family: 'Roboto', sans-serif;
}

.hue-horizontal {
  height: 20px !important;
  width: 100% !important;
  /* width: 400px !important; */
  position: relative;
}

.slider-picker div:first-child {
  height: 30px !important;
  position: relative;
  transform: scale(1.0) !important;
}

.slider-picker div:first-child:focus {
  height: 20px !important;
  position: relative;
  transform: scale(1.2) !important;
  /* transform: scaleY(1.0) !important;
  transform: scaleX(1.0) !important; */
}

.slider-picker div:first-child ::selection {
  height: 20px !important;
  position: relative;
  transform: scale(1.2) !important;
  /* transform: scaleY(1.0) !important;
  transform: scaleX(1.0) !important; */
}

/* div.slide-picker{
  height:220px !important;
} */

.home{
  background: url('Image/pattern2.png');
  background-size: cover;
  max-height: 94.6vh;
  overflow: hidden;
}
.App {
  text-align: center;
  max-height: 100vh;
  padding: 0;
  margin: 0;
}

.track {
  top: "19px"
}

.trackFill {
  top: "19px"
}

.semantic_ui_range_inner > div:last-child {
  background-color: red;
  width: 40px;
  height: 40px
}

/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.disable-select {
  user-select: none;
}

.fading {
  opacity: 1;
  animation: fadeIn 5s ease;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.aboveOthers {
  z-index: 800 !important;
  position: absolute;
}

.react-colorful {
  height: 250px;
  z-index: 40;
  position: relative;
}

.react-colorful__saturation {
  border-radius: 3px 3px 0 0;
}

.react-colorful__hue {
  height: 30px;
  border-radius: 0 0 3px 3px;
}

.react-colorful__saturation-pointer {
  border-radius: 5px;
}

.react-colorful__hue-pointer {
  border-radius: 2px;
  width: 15px;
  height: inherit;
}

.canvas-board {
  margin: auto, 0;
  width: 100vw;
  /* margin-left: 50px;
  margin-right: 50px !important;
  min-width: 75%; */
}

.Main_Board{
  width: 100%;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 11fr;
}

.icons_listing{
  display: grid;
  width: 90%;
  grid-template-columns: 1fr 1fr;
  margin: auto;
}

.icons_listing button{
  /* width: 50%; */
  /* margin-top: 10px;
  margin-bottom: 10px; */
  margin: 15px auto;
}

.slider_width{
  width: 200px;
}

.color_palette{
  margin-top: 10px;
  /* width: 90%; */
}

.button_width{
  width: 90%;
}

.HexColorPicker_containter{
  width: 90%;
}

.navigation a:hover{
  color: rgb(255, 255, 255);
}